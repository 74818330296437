<template>
    <div class="head-box">
        <div class="head-logo">
        	<img src="../../assets/logo.png" alt="" />
        </div>
		<div class="head-nav-list">
			<div class="head-nav-list-item" v-for="(item,index) in navList" :style="{color:listColor}" :class="tabId == index?'active':''" @click="tabHandle(index)">
				{{item.name}}
			</div>
		</div>
    </div>
</template>

<script>
import { removeCookie } from '@/utils/cookie'

export default {
    name: 'myheader',
	props:{
		listColor:{
			default:'#fff',
			type:String
		}
	},
    data() {
        return {
			tabId:0,
            navList:[
				{
					name:'首页'
				},
				{
					name:'关于我们'
				},
				{
					name:'服务'
				},
				{
					name:'案例'
				},
				{
					name:'联系我们'
				}
			]
        }
    },
    created() {
       
    },
    methods: {
        tabHandle(index){
			this.tabId = index
		}
    }
}
</script>

<style lang="less" scoped>
.head-box {
	height: 100%;
    display: flex;
	justify-content: space-between;
	align-items: center;
	.head-logo{
		height: 52px;
		img{
			width: 251px;
			height: 52px;
		}
	}
	.head-nav-list{
		display: flex;
		height: 60px;
		.head-nav-list-item{
			padding: 0 15px;
			line-height: 60px;
			color: #fff;
			font-size: 22px;
			cursor: pointer;
			margin: 0 5px;
		}
		.head-nav-list-item:hover{
			color: #95cfff;
		}
		.head-nav-list-item.active{
			position: relative;
			&::after{
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				height: 2px;
				background-color: #95cfff;
				content: '';
			}
		}
	}
}
</style>