<template>
	<div class="footer-box">
		<div class="Copyright">
			<span>Copyright &copy; 2023-2024 xrcnts.com All Rights Reserved. 辛荣信息技术服务 版权所有</span>
			<a v-if="false" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=10000000000" target="_blank" class="beian_number">
				<img src="https://ssr-static.jlit8.cn//static/images/gawb.png" style="margin-top:1px;"> 
				桂公网安备 10000000000号
			</a>
			<el-link style="margin-left: 6px;" href="http://beian.miit.gov.cn" target="_blank">桂ICP备2023005044号-1</el-link>
		</div>
	</div>
</template>

<script>
	export default {
	    name: 'myfooter',
	    data() {
	        return {
	            
	        }
	    },
	    created() {
	       
	    },
	    methods: {
	        
	    }
	}
</script>

<style lang="less" scoped>
	.footer-box{
		padding: 30px 0;
		.Copyright{
			text-align: center;
			color: #606266;
			font-size: 14px;
		}
	}
</style>