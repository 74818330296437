<template>
    <div class="layout">
		<el-scrollbar class="layout-box" ref="scrollbar">
		    <div class="layout-head" :class="isfiexd?'layout-head-fiexd':''">
		    	<div class="layout-head-box">
		    		<MyHeader :list-color="isfiexd?'#333':''"></MyHeader>
		    	</div>
		    </div>
		    <div class="layout-main">
		    	<el-container>
		    	  <el-main>
		    		  <router-view></router-view>
		    	  </el-main>
		    	  <el-footer>
		    		  <MyFooter></MyFooter>
		    	  </el-footer>
		    	</el-container>
		    </div>
		    <el-backtop target=".layout-box .el-scrollbar__wrap" :bottom="100">
		        <div
		          style="{
		            height: 100%;
		            width: 100%;
		            background-color: #f2f5f6;
		            box-shadow: 0 0 6px rgba(0,0,0, .12);
		            text-align: center;
		            line-height: 40px;
		            color: #1989fa;
		          }"
		        >
		          TOP
		        </div>
		    </el-backtop>
		</el-scrollbar>
    </div>
</template>

<script>
import MyHeader from './MyHeader.vue'
import MyFooter from './MyFooter.vue'
export default {
    name: 'layout',
    components: {
        MyHeader,
        MyFooter
    },
	data(){
		return{
			isfiexd:false,
		}
	},
	mounted() {
		this.handleScroll()
	},
	methods:{
		handleScroll() {
			// 处理滚动事件
			let scrollbarEl = this.$refs.scrollbar.wrap;
			let _this = this;
			scrollbarEl.onscroll = () => {
				if (scrollbarEl.scrollTop - 820 >=0) {
					_this.isfiexd = true
				}else{
					_this.isfiexd = false
				}
			};
		}
	}
}
</script>

<style lang="less" scoped>
	.layout{
		.layout-box {
			width: 100%;
			height: 100%;
			position: fixed;
			z-index: 1;
			::v-deep .el-scrollbar__wrap{
				overflow-x: auto;
			}
			::v-deep .el-scrollbar__bar{
				z-index: 99;
			}
		    .layout-head{
				width: 100%;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999;
				.layout-head-box{
					width: 1200px;
					height: 120px;
					margin: 0 auto;
					padding-top: 30px;
					box-sizing: border-box;
				}
			}
			.layout-head.layout-head-fiexd{
				background-color: #fff;
				box-shadow: 0 0 10px 4px rgba(0,0,0,.4);
			}
			.layout-main{
				.el-main{
					padding: 0;
				}
			}
		}
	}
    
</style>